import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import Modal from '../UI/Modal/Modal';
import axios from '../../../axios-inventory';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';




class Customers extends Component {
  state = {
    items: Array.from({ length: 10 }),
    fetchedProduct: [],
    hasMore: true,
    show: false,
    offset: 0,
    limit: 10,
    searchText: '',
  };

  getCustomer(searchText = '') {

    axios.get(`/customer`, {

      params: {
        searchText: searchText,
        // category: '',
        offset: this.state.offset,
        limit: this.state.limit
      },


    }).then(res => {


      var fetchOrders = res.data["Data"];




      var fetchOrdersUpdated = this.state.offset != 0 ? [...this.state.fetchedProduct, ...fetchOrders] : fetchOrders
      
      this.setState({ fetchedProduct: fetchOrdersUpdated });




      if (res.data["Data"].length < this.state.limit) {
        this.setState({ hasMore: false });


      }
    
    }).catch(err => {
      this.setState({ hasMore: false });

    });
  }


  fetchMoreData = () => {

    if (this.state.hasMore) {

      this.setState({
        offset: this.state.offset + this.state.limit
      }, () => {
        this.getCustomer();

      });
    }
  }

  showmodalHandler = (id, text, status) => {

    if(this.tooltipRef != undefined){
    this.tooltipRef.tooltipRef = null;
    ReactTooltip.hide();
  }
    this.setState({ show: true });
    if (text == "Add") {
      this.ModalRef.onModalOpenHandler()
    } else

      if (id != "0") {
        this.ModalRef.onEditHandler(id, text, status);
      }
  }


  onHideHandler = (userid, formData, id=undefined) => {
    this.setState({ show: false });
    
    
    if (formData == undefined) {

    if (id != 0 ){       

      this.props.history.push('/customers/' + id);
    }

      this.setState({
        fetchedProduct: [],
        offset: 0,
        hasMore: true,

      }, () => {
        this.getCustomer();

      })
      return }

    var supIndex = this.state.fetchedProduct.findIndex(obj => obj.id == userid);

    var sups = this.state.fetchedProduct;
    let s = sups[supIndex];
  
     
    if (Object.keys(formData).length == "1") {
      s.status = formData["status"];
      
    } 
    else {
      

      s.name = formData["name"];
      s.shortCode = formData["shortCode"];
      s.contactEmail = formData["contactEmail"];
        }


    sups.splice(supIndex, 1, s);

        
    this.setState({fetchedProduct : sups})
    

  }

  componentDidMount() {

    this.getCustomer();
  }


  onsearch(text) {

    this.setState({
      fetchedProduct: [],
      offset: 0,
      hasMore: true,

    }, () => {
      this.getCustomer(text);

    })
  }

  render() {
    return (
      <React.Fragment>
        <Modal show={this.state.show}
          onHide={(userid, formData,id) => this.onHideHandler(userid, formData,id)}
          ref={ref => this.ModalRef = ref} />

        <div className="slim-mainpanel">
          <div className="table-top-section">
            <div className="left-sec">
              <h2 className="page-title" style={{ fontWeight: "bold" }}>Customers</h2>
            </div>
            <div className="right-sec">
              <form className="search-frm" onSubmit={e => { e.preventDefault() }}>
                {/* onChange={(event) => { this.onsearch(event.target.value) }} */}
                <input type="text" name="search-product"  onChange={(event) => { this.onsearch(event.target.value) }}  placeholder="Search Customers...." />
              </form>
              {/* <a className="download-btn"><img src="./img/download-btn.svg" /></a> */}
              <button class="btn btn-success active btn-flex mg-b-10 mg-l-24 "
                onClick={() => this.showmodalHandler("0","Add")}
                style={{
                  borderRadius: "5px", letterSpacing: "0.8px", textAlign: "center", backgroundColor: "#23A956", borderColor: "#23A956", alignItems: "center",
                  lineHeight: "17px", color: "white", marginLeft: "24px", width: "109px", height: "36px", fontSize: "12px"
                }}> <label style={{ marginLeft: "-1px", cursor:"pointer" }}> Add Customer</label> </button>
            </div>
            <img />
          </div>
          <div className="card card-table">
            <div className="tbl-header" style={{background:"#F8F9FA"}}>
              <table className="table mg-b-0 tx-13 " style={{width: "calc(100% - 1em)"}}>
                <thead>
                  <tr className="tx-10">
                    <th className="pd-y-5" style={{ width: "26.25%" }}>CUSTOMER NAME <img src="./img/Arrow.svg" /></th>
                    <th className="pd-y-5" style={{ width: "9.54%" }}>SHORTCUT</th>
                    <th className="pd-y-5 " style={{ width: "26.40%" }}>E-MAIL FOR RECEIPTS</th>
                    <th className="pd-y-5 tx-center" style={{ width: "11.81%" }}>ACTIVE USER</th>
                    <th className="pd-y-5 " style={{ width: "11.39%" }}>STATUS</th>
                    <th className="pd-y-5 tx-right" style={{ width: "9.43%" }}></th>
                    <th className="pd-y-5 tx-right" style={{ width: "175px" }}></th>


                  </tr>
                </thead>
              </table>
            </div>
            <div id="scrollableDiv" style={{ Width: '100%', overflow: 'auto', maxHeight: '600px' }}>
              <table className="table mg-b-0 tx-13 ">
                <InfiniteScroll
                  dataLength={this.state.fetchedProduct.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.hasMore}
                  loader={<p style={{ textAlign: "center" }}>
                    <b>Loading....</b>
                  </p>}
                  height={400}
                  endMessage={
                    <h2 style={{ textAlign: "center" }}>
                      <br/><br/><br/>
                     {this.state.fetchedProduct.length > 0 ? "" : " No result found"}
                    </h2>}
                  scrollableTarget="scrollableDiv"
                >
                  <tbody>
                    {this.state.fetchedProduct.map(customer => (
                      <tr className="valign-middle">
                        <td className="valign-middle" style={{ width: "26.25%" }}>{customer.name}</td>
                        <td className="valign-middle" style={{ width: "9.54%" }}>{customer.shortCode}</td>
                        <td className="valign-middle tx-left" style={{ width: "26.40%" }}>{customer.contactEmail}</td>
                        <td className="valign-middle tx-center" style={{ width: "11.81%" }} >{customer.activeUsers}</td>
                        <td className="valign-middle tx-left" style={{ width: "11.39%" }}>{customer.status == "1" ? <img src="./img/button.png" /> : <img src="./img/Disabled.png" />} </td>
                        <td className="valign-middle tx-left" style={{ width: "9.43%" }}><Link to={"/customers/" + customer.id} ><u style={{ color: "#168442" }}><b>See details</b></u></Link></td>
                        <td className="valign-middle tx-center" style={{ width: "175px" }}>
                          
                          <a className="moreicon"  data-tip={ JSON.stringify(customer) }
                          data-for='customerTooltip'  ref={(c) => { this.ref = c; }}  data-event='click'>...</a>
                          
                          <ReactTooltip className='customtooltip' id='customerTooltip' clickable='true'  
                            globalEventOff='click' place="left" effect="float" type='light' ref={ref => this.tooltipRef = ref}
                            getContent={datumAsText => {
                              if (datumAsText == null) {
                                return;
                              }
                              
                              let d = JSON.parse(datumAsText);
                              return (
                                <div className="tabletooltipinner">
                                <ul>
                                  <li ><a onClick={() => this.showmodalHandler(d.id,"Edit",d.status)} onMouseLeave={() => { ReactTooltip.hide(this.ref); }}>Edit Customer info</a></li>
                                  <li><a onClick={() => this.showmodalHandler(d.id,"Disable",d.status  )} 
                                  onMouseLeave={() => { ReactTooltip.hide(this.ref); }}>{d.status == "1" ? "Disable":"Enable"} Customer</a></li>
                                </ul>
                              </div>
                              
                              );
                            }}
                            offset={{ top: -60, left: -30 }}>

                          </ReactTooltip>
                        </td>

                      </tr>
                    ))}

                  </tbody>
                </InfiniteScroll>
              </table>
            </div>{/* table-responsive */}
          </div>{/* card */}
        </div>
      </React.Fragment>
    );
  }
}

export default Customers;
