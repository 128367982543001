import React, { Component } from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';
import axios from '../../../../axios-inventory';
import { withAlert } from 'react-alert';


var FormElement = {
    name: {
        label: 'FULL NAME',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'eg. Ola Nordmann'
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    email: {
        label: 'E-MAIL',
        elementType: 'input',
        elementConfig: {
            type: 'email',
            placeholder: 'eg. Ola@yourdomain.com',

        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    }
}

class ModalStoreUser extends Component {

    state = {
        orderForm: FormElement,
        formIsValid: false,
        loading: false,
        isEdit: false,
        isDisable: false,
        isStoreUserStatus: false,
        userId: 0,
        userRole: "0",
        isChecked: "0",

    }



    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.minLength && isValid
        }

        return isValid
    }


    addUserHandler(event, text) {
        console.log(text)
        event.preventDefault();
        const url = this.props.custUsr ? "customerusers" : "users";


        const formData = {};
        for (let formElementIdentifier in this.state.orderForm) {
            formData[formElementIdentifier] = this.state.orderForm[formElementIdentifier].value;
        }

        const abc = formData["name"].split(' ');
        console.log(abc.length)
        console.log(text)
        if (abc.length == 2) {
            if (abc[1].length > 0) {
                formData["firstName"] = abc[0] ? abc[0] : " ";
                formData["lastName"] = abc[1] ? abc[1] : " ";

                delete formData["name"];



                if (!this.props.custUsr) {
                    formData["userRoleId"] = this.state.userRole;

                } else {
                    formData["customerId"] = this.props.custId;
                }


                if (text == "Add") {




                    axios.post(`/${url}`, formData)
                        .then(res => {
                            
                            if(res.data.Status != false){
                            this.props.alert.success("User has been added");
                        }
                            else{
                                this.props.alert.error(res.data.Message);
                            }
                            this.onCloseHandler();
                        })
                        .catch(error => {
                            this.props.alert.error(error.message);
                            this.onCloseHandler();
                        })
                }
                else if (text == "Edit") {
 



                    axios.put(`/${url}/${this.state.userId}`, formData)
                        .then(response => {
                            if(response.data.Status != false){
                            this.props.alert.success("User Edited");
                            }else{
                                this.props.alert.error(response.data.Message);
                            
                            }
                            this.onCloseHandler(formData);
                        })
                        .catch(error => {

                            this.props.alert.error(error.message);
                            this.onCloseHandler();

                        });



                }
                else if (text == "Disable") {
                    axios.delete(`/${url}/${this.state.userId}`).then(res => {
                        
                        if(res.data.Status == false){
                            this.props.alert.error(res.data.Message);

                        }

                    }).catch(error => {
                        this.props.alert.error(error.message);

                        this.onCloseHandler();

                    });

                    var s = this.state.isStoreUserStatus ? "0" : "1";

                    var status = { 'status': s };
                    this.onCloseHandler(status);


                }

            } else {
                this.setState({ errmsg : true});

            }
        } else {
            console.log("hello")

            if (text == "Disable") {
                axios.delete(`/${url}/${this.state.userId}`).then(res => {
                    
                    if(res.data.Status == false){
                        this.props.alert.error(res.data.Message);

                    }

                }).catch(error => {
                    this.props.alert.error(error.message);

                    this.onCloseHandler();

                });

                var s = this.state.isStoreUserStatus ? "0" : "1";

                var status = { 'status': s };
                this.onCloseHandler(status);


            }else{
            this.setState({ errmsg : true});}

        }


    }


    inputChangedHandler = (event, inputIdentifire) => {
        const updatedOrderForm = {
            ...this.state.orderForm,

        }
        const updatedFormElement = { ...updatedOrderForm[inputIdentifire] };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedOrderForm[inputIdentifire] = updatedFormElement;
        
        let formIsValid = true;
        for (let inputIdentifire in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifire].valid && formIsValid;
        }
        this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });
    }

    onModalOpenHandler() {

        this.state.orderForm["name"].value = "";
        this.state.orderForm["email"].value = "";
        this.setState({ userRole: "0" });

    }

    onCloseHandler = (formData = undefined) => {

        this.setState({

            orderForm: FormElement,
            loading: false,
            formIsValid: false,
            isEdit: false,
            isDisable: false,
            isStoreUserStatus: false,
            isChecked: "0",
            errmsg: false,



        });


        if (formData != undefined) {


            if (Object.keys(formData).length == "1") {
                var newdics = formData
            }
            else {



                formData["userRoleId"] = this.state.userRole == "1" ? "User" : "Admin";
                var newdics = { 'FirstName': formData["firstName"], 'LastName': formData["lastName"], 'Email': formData["email"], 'UserRole': formData["userRoleId"] }
            }


            this.props.onHide(this.state.userId, newdics);
        }
        else {

            this.props.onHide(this.state.userId, undefined);
        }
    }

    onCancel(e) {
        e.preventDefault();
        this.onCloseHandler();

    }

    onEditHandler(id, text, status) {
        var url = this.props.custUsr ? "customerusers" : "users";


        this.setState({ userId: id });
        if (text == "Disable") {


            this.setState({ isDisable: true });
            if (status == "1") {
                this.setState({ isStoreUserStatus: true });
            }
        } else if (text == "Edit") {

            this.setState({ isDisable: false, isStoreUserStatus: false })
            axios.get(`/${url}/${id}`).then(res => {

                var fetchedStoreUser = res.data["Data"];




                this.state.orderForm["name"].value = fetchedStoreUser['firstName'] + " " + fetchedStoreUser['lastName'];
                if (fetchedStoreUser['firstName'].trim() != "") {
                    this.state.orderForm["name"].valid = true
                }

                this.state.orderForm["email"].value = fetchedStoreUser['email'];
                if (fetchedStoreUser['email'].trim() != "") {
                    this.state.orderForm["email"].valid = true;
                }


                if (this.props.custUsr) {

                    this.setState({ isEdit: true, formIsValid: true });

                } else {



                    const userRole = fetchedStoreUser['userRoleId'];
                    const userRoleName = fetchedStoreUser['userRoleId'] == "1" ? "user" : "admin"
                    this.setState({ isEdit: true, formIsValid: true, userRole: userRole, isChecked: userRoleName });


                }





            }).catch(err => {
                console.log("ERROR " + err);

            })
        }
    }


    setUserRole(event) {

        var role = "0"

        if (event.target.value == "user") {
            role = "1"
        } else if (event.target.value == "admin") {
            role = "2"
        }
        this.setState({ userRole: role, isChecked: event.target.value });
    }



    render() {

        const showmodal = this.props.show;
        var classmy = "none";
        if (showmodal) {
            classmy = "flex";
        }

        const formElementsArray = [];
        for (let key in this.state.orderForm) {
            formElementsArray.push({
                id: key,
                config: this.state.orderForm[key]
            });
        }


        let form = (
            <form className="popupform" onSubmit={(event) => this.addUserHandler(event, this.state.isEdit ? "Edit" : "Add")}>

                {formElementsArray.map(formElement => (

                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        label={formElement.config.label}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />

                ))}
                {this.props.custUsr ? null :
                    <div className="form-element-group">
                        <label>User role</label>
                        <div className="radio-toolbar" onChange={this.setUserRole.bind(this)}>
                            <input type="radio" id="radioyes" name="radio_do_u_k_hmf" defaultValue="user" checked={this.state.isChecked == "user" ? true : false} className="radio_do_u_k_hmf" />
                            <label className="lblfirst" htmlFor="radioyes" >User</label>
                            <input type="radio" id="radiono" name="radio_do_u_k_hmf" defaultValue="admin" checked={this.state.isChecked == "admin" ? true : false} className="radio_do_u_k_hmf" />
                            <label htmlFor="radiono">Admin</label>
                        </div>
                    </div>
                }

                <div className="form-element-group">
                    {this.state.errmsg ? <span class="errmsg">Please Enter FullName "eg. Joe Deo" </span> : ""}
                </div>

                <div className="form-element-group">
                    <div className="buttonsection">
                        <button type="submit" onClick={(e) => this.onCancel(e)} className="actionbutton secondary">Cancel</button>
                        <button type="submit" disabled={!this.state.formIsValid} className="actionbutton primary">{(this.state.isEdit ? "Save changes" : "Send Invite")}</button>
                    </div>
                </div>
            </form>
        );

        let formDisable = (
            <form id="create-course-form" className="popupform" onSubmit={(event) => this.addUserHandler(event, "Disable")}>

                <h2 class="page-title">{this.state.isStoreUserStatus ? "Disable" : "Enable"} Customer App Access</h2>

                <div>If you disable a customer that no longer cooperates with you, all users from this customer will lose access to the customer application.</div>

                <div className="form-element-group">
                    <div className="buttonsection">
                        <button type="reset" onClick={(e) => this.onCancel(e)} className="actionbutton secondary">Cancel</button>
                        <button type="submit" className="actionbutton primary" style={this.state.isStoreUserStatus ? { background: "#E1464D" } : { background: "#23A956" }}>Yes, {this.state.isStoreUserStatus ? "Disable" : "Enable"}</button>
                    </div>
                </div>
            </form>
        );

        return (

            <div id="mypopup" className={this.state.isDisable ? "modalpopup disablepopup" : "modalpopup"} style={{ display: classmy }}>

                {/* Modal content */}
                <div className="modal-popup-content">
                    <div className="popup-header">
                        {this.state.isDisable ? <h2 className="popup-title"></h2> : <h2 className="popup-title">{(this.state.isEdit ? "Edit user Info" : "Add a user")}</h2>}
                        <span className="popupclose">
                            <a onClick={(e) => this.onCancel(e)} ><img src="./img/closepopup-icon.svg" /></a></span>
                    </div>
                    <div className="popupbody">


                        {this.state.isDisable ? formDisable : form}





                    </div>
                </div>
            </div>

        );
    }
}

export default withAlert()(ModalStoreUser);