import React, { Component } from 'react';
import { NavLink, Link, matchPath, withRouter } from 'react-router-dom';
import { EventEmitter } from "../../../event";

import { history } from '../../../_helpers/history';
import { authenticationService } from '../../../_services/authentication.service';
import axios from '../../../axios-inventory';
import Axios from 'axios';




class Header extends Component {

  state = {
    custName: "",
    currentUser: null,
    storeName: "",
  }



  componentWillMount(){


  }

  componentDidMount() {

    authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    EventEmitter.on('username', (name) => this.setState({ custName: name }));

  }


  logout() {

    // console.log(axios.defaults.headers["ApiKey"]);


    delete axios.defaults.headers["ApiKey"];
    // Axios.interceptors.request.eject(axios);

    // console.log(axios.head());
    //  console.log(axios.defaults.headers["ApiKey"]);
    // axios.request({headers: {'X-Requested-With': 'XMLHttpRequest'}});
    authenticationService.logout();
    this.props.history.push('/login');
    // window.location.href = "/login";


  }

  checkLink() {
    var abc = ""
    const re = /^[0-9\b]+$/;
    if (this.props.location.pathname.slice(1) == "orgs") {
      abc = "Organization setting";
    } else if (this.props.location.pathname.slice(1) == "profile") {
    }

    else if (re.test(this.props.location.pathname.slice(11))) {
      abc = "Profile setting";

      abc = <div> Customer / <span>{this.state.custName}</span></div>

    }

    return abc
  }

  render() {
    var title = this.checkLink();
    // var UserName = authenticationService.currentUserValue.User["FirstName"] + " " + authenticationService.currentUserValue.User["LastName"] ;
    var UserName = authenticationService.currentUserValue.User["FirstName"] + " " + authenticationService.currentUserValue.User["LastName"];

    
    var store = JSON.parse(localStorage.getItem('currentUser'));
    var StoreName = store.User.StoreName;
    var abc = this.state.currentUser != null ? this.state.currentUser.User["FirstName"] : null;


    return (
      <div>
        <div className="slim-header with-sidebar">
          <div className="container-fluid">
            <div className="slim-header-left">
              <h2 className="slim-logo">
                <Link to="/"><img src="./img/LogoTotalCtrl_White.png" /></Link>

              </h2>
              <a href id="slimSidebarMenu" className="slim-sidebar-menu"><span /></a>
            </div>{/* slim-header-left */}

            <div className="slim-header-right">
              <div class="header-breadcrumbs">
                {title}
                {/* Customers / <span>TotalCtrl</span> */}
              </div>
              <div className="dropdown dropdown-c">
                <a href="#" className="logged-user" data-toggle="dropdown">
                  <img src="./img/brandmark.svg" alt="" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="profilemenu-header">
                    <ul>
                      <li><img src="./img/brandmark.svg" /></li>
                      <li><h5 className="organizationname">{UserName} <span>{StoreName}</span></h5>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="profilemenu-menuitems">
                      <ul className="profilemenu-menuitems-ul">
                        <li>
                          <img src="./img/profilesetting-icon.svg" />
                          <Link to="/profile" >Profile settings</Link>
                        </li>
                        <li>
                          <img src="./img/organaisationsetting-icon.svg" />
                          <Link to="/orgs">Organisation settings</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="profilemenu-bottom">
                      <ul className="profilemenu-menuitems-ul">
                        <li>
                          <img src="./img/logout-icon.svg" />
                          <Link onClick={() => this.logout()}>Log out</Link>
                        </li>
                      </ul>
                    </div>
                  </div>


                </div>{/* dropdown-menu */}
              </div>{/* dropdown */}
            </div>{/* header-right */}
          </div>{/* container-fluid */}
        </div> {/* slim-header */}
      </div>
    );
  }
}

export default withRouter(Header);