
//dev
// export const url = "http://52.18.237.46/matsentralen/api/v2/" ;

// Prod
// export const url = "http://production.totalctrl.com/food-bank/api/v2/" ;
// export const url = "https://app.totalctrl.com/food-bank/api/v2/" ;
// export const url = "https://app.totalctrl.com/matsentralen/api/v2/" ;
export const url = "https://foodbank.totalctrl.com/api/v2/";
export const urlImport = "http://handstogethertech.com/totalctrlcharity/api/v3/" ;


