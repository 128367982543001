import React, { Component } from 'react';
import Input from '../Input/Input';
import axios from '../../../../axios-inventory';
import { withAlert } from 'react-alert';


var FormElement = {
    name: {
        label: 'SUPPLIER NAME',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Enter supplier Name'
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    shortCode: {
        label: 'SUPPLIER SHORTCUT',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Enter supplier shortcut',

        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    }
}

class ModalSuppliers extends Component {


    state = {

        orderForm: FormElement,
        formIsValid: false,
        loading: false,
        isChecked: false,
        isEdit: false,
        isDisable: false,
        isSupplierStatus: false,
        userId: 0,
    }




    checkValidity(value, rules) {
        let isValid = true;

        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.minLength && isValid
        }

        return isValid
    }


    addUserHandler(event, text) {
        event.preventDefault();
        this.setState({ loading: true });

        const formData = {};


        for (let formElementIdentifier in this.state.orderForm) {
            formData[formElementIdentifier] = this.state.orderForm[formElementIdentifier].value;
        }

        formData["isFeatured"] = this.state.isChecked ? "1" : "0";
    
        if (text == "Add") {

            axios.post('/suppliers', formData)
                .then(res => {
                    if(res.data.Status != false){ 
                    this.props.alert.success("Supplier has been added");
                    }else{
                        this.props.alert.error(res.data.Message);

                    }
                    this.onCloseHandler();
                })
                .catch(error => {
                   
                    this.props.alert.error(error.message)
                    this.onCloseHandler();


                });

        } else if (text == "Edit") {

            axios.put(`/suppliers/${this.state.userId}`, formData)
                .then(res => {
                    if(res.data.Status != false){ 
                    this.props.alert.success("Supplier edited ");
                    }else{
                        this.props.alert.error(res.data.Message);
                    }

                    this.onCloseHandler(formData);
                })
                .catch(error => {
                   
                    this.props.alert.error(error.message)
                    this.onCloseHandler();

                });

        } else if (text == "Disable") {
           
            axios.delete(`/suppliers/${this.state.userId}`).then( res =>{
                if(res.data.Status == false){
                    this.props.alert.error(res.data.Message);

                }
            }

            ).catch(error => {
                this.props.alert.error(error.message)

            });
            var s = this.state.isSupplierStatus ? "0" : "1";            
            var status = { 'status': s };
            this.onCloseHandler(status);
        }
    }


    inputChangedHandler = (event, inputIdentifire) => {
        const updatedOrderForm = {
            ...this.state.orderForm,

        }
        const updatedFormElement = { ...updatedOrderForm[inputIdentifire] };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedOrderForm[inputIdentifire] = updatedFormElement;
       

        let formIsValid = true;
        for (let inputIdentifire in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifire].valid && formIsValid;
        }
        
        this.setState({ orderForm: updatedOrderForm, formIsValid: formIsValid });
    }

    onModalOpenHandler() { 
     
        this.state.orderForm["name"].value = "";
        this.state.orderForm["shortCode"].value = "";
        this.setState({ isChecked: false, formIsValid: false});
    }

    onCloseHandler = (formData = undefined) => {
        this.setState({
            orderForm: FormElement,
            formIsValid: false,
            isEdit: false,
            isDisable: false,
            isSupplierStatus: false
        });
     
        if (formData != undefined) {
         
            if (Object.keys(formData).length == "1") {
                var newdics = formData
            } else {
                var newdics = { 'name': formData["name"], 'shortCode': formData["shortCode"], "isFeatured": this.state.isChecked ? "1" : "0" }
            }

            
            
            this.props.onHide(this.state.userId, newdics);
        } else {
            this.props.onHide(this.state.userId, undefined);

        }

    }


    onCancel(e) {
        e.preventDefault();
        this.onCloseHandler();

    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }

    onEditHandler(id, text,status) {
        this.setState({ userId: id })
        
        if (text == "Disable") {
            this.setState({ isDisable: true })
            if (status == "1") {
                this.setState({ isSupplierStatus: true });
            }
        } else if (text == "Edit") {
            this.setState({ isDisable: false })
            axios.get(`/suppliers/${id}`, {

                params: {
                    name: '',
                    category: '',
                    offset: this.state.offset,
                    limit: this.state.limit
                },


            }).then(res => {

                var fetchedSupplier = res.data["Data"];
                this.state.orderForm["name"].value = fetchedSupplier['Name'];
                if (fetchedSupplier['Name'].trim() != "") {
                    this.state.orderForm["name"].valid = true
                }

                this.state.orderForm["shortCode"].value = fetchedSupplier['ShortCode'];
                if (fetchedSupplier['ShortCode'].trim() != "") {
                    this.state.orderForm["shortCode"].valid = true;
                }
                var isFetaured = fetchedSupplier['Featured'] != 0 ? true : false;


                this.setState({ isEdit: true, isChecked: isFetaured, formIsValid: true });
                console.log(this.state.isChecked);



            }).catch(err => {
                console.log("ERROR " + err);

            })
        }
    }



    render() {



        const showmodal = this.props.show;
        var classmy = "none";
        if (showmodal) {
            classmy = "flex";
        }

        const formElementsArray = [];
        for (let key in this.state.orderForm) {
            formElementsArray.push({
                id: key,
                config: this.state.orderForm[key]
            });
        }




        let form = (
            <form id="create-course-form" className="popupform" onSubmit={(event) => this.addUserHandler(event, this.state.isEdit ? "Edit" : "Add")}>

                {formElementsArray.map(formElement => (

                    <Input
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        label={formElement.config.label}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />

                ))}


                <div className="form-element-group">
                    <label className="checkbox-group">Show supplier among top suppliers in mobile app
                        <input type="checkbox" checked={this.state.isChecked} onChange={this.toggleChange} />
                        <span className="checkmark" />
                    </label>
                </div>

                <div className="form-element-group">
                    <div className="buttonsection">
                        <button type="reset" onClick={(e) => this.onCancel(e)} className="actionbutton secondary">Cancel</button>
                        <button type="submit" disabled={!this.state.formIsValid} className="actionbutton primary">Save</button>
                    </div>
                </div>
            </form>
        );

        let formDisable = (
            <form id="create-course-form" className="popupform" onSubmit={(event) => this.addUserHandler(event, "Disable")}>

                <h2 class="page-title">{this.state.isSupplierStatus ? "Disable" : "Enable"} Supplier</h2>
                {this.state.isSupplierStatus ? <div>
                    If you disable a supplier that no longer cooperates with you, all users of this supplier will be disabled.</div>
                : "Enable Supplier"  
                
            }

                <div className="form-element-group">
                    <div className="buttonsection">
                        <button type="reset" onClick={(e) => this.onCancel(e)} className="actionbutton secondary">Cancel</button>
                        <button type="submit" className="actionbutton primary" style={this.state.isSupplierStatus ? { background: "#E1464D" } : { background: "#23A956" }}>Yes, {this.state.isSupplierStatus ? "Disable" : "Enable"}</button>
                    </div>
                </div>
            </form>
        );


        return (

            <div id="mypopup" className={this.state.isDisable ? "modalpopup disablepopup" : "modalpopup"} style={{ display: classmy }}>

                {/* Modal content */}
                <div className="modal-popup-content"   >
                    <div className="popup-header">
        {this.state.isDisable ? <h2 className="popup-title"></h2> : <h2 className="popup-title">{(this.state.isEdit ? "Edit supplier Info" : "Add a supplier")}</h2>}
                        <span className="popupclose">
                            <a onClick={(e) => this.onCancel(e)}><img src="./img/closepopup-icon.svg" /></a></span>
                    </div>
                    <div className="popupbody">


                        {this.state.isDisable ? formDisable : form}





                    </div>
                </div>
            </div>

        );
    }
}

export default withAlert()(ModalSuppliers);