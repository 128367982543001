import React, { Component } from 'react';
import axios from '../../../axios-import';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import Spinner from '../UI/Spinner/Spinner';




class DataExport extends Component {
    state = {xlData: "",
            error:"",
            isError: false,
            isLoading: false};


    importData = () => {

        var file = document.querySelector("#file").value;
        var extension = file.substr(file.lastIndexOf('.') + 1).toLowerCase();
        var reg = /(.*?)\.(xls|xlsx|csv)$/;
        

        if (reg.test(file)) {
            console.log(this.state.xlData);
            this.setState({isLoading:true,isError: false, error:""});
            axios.post(`/productTemplates`, this.state.xlData).then(res => {
              console.log(res.data.Status);
              if(res.data.Status){
              this.setState({isError: false,isLoading:false});
              this.setState({error:"Data uploaded successfully."});
              }else{
              this.setState({error:"There is some issue with file",isLoading:false, isError: true});
                  
              }

            }
              
              ).catch(err => {
              console.log(err)
              this.setState({error:"There is some issue with file",isLoading:false, isError: true});


          })
            
        } else {
            this.setState({  isError: true , error:"Invalid File please use valid file eg. .xlsx"})
            
            

        }

        this.setState({ xlData: ""});
        
    }

    handleImport = (evt) => {

        var self = this;
        var reader = new FileReader();
        var file = evt.target.files[0];


        //just pass the fileObj as parameter
        ExcelRenderer(file, (err, resp) => {
            if (err) {
                console.log(err);
            }
            else {
                console.log(resp);

                const rows = resp.rows;
                const keys = rows[0];
                let data = [];
                for (let i = 1; i < rows.length; i++) {
                    let dataDic = {};
                    let rowData = rows[i];
                    for (let j = 0; j < keys.length; j++) {
                        if (rowData[j] == null || rowData[j] == undefined) {
                            dataDic[keys[j]] = "";
                        }
                        else {
                            dataDic[keys[j]] = rowData[j];
                        }
                    }
                    data.push(dataDic);
                }

                // this.importData("data:" + JSON.stringify(data));
            
                let newdic = {"data" : data}
                this.setState({
                    xlData: newdic  
                })


            }
        });

    }

    render() {
        let orderSummary = null;
        if (this.state.isLoading) {
            
            orderSummary = <Spinner />;
        }

        return (
            <React.Fragment>
                <div className="slim-mainpanel">
                    <div className="table-top-section">
                        <div className="left-sec">
                            <h2 className="page-title">Data Import</h2>
                        </div>
                        <div className="right-sec">
                        </div>
                        <img />
                    </div>
                    <div className="customtabpanel">
                        <div id="exTab1" className>
                            <ul className="nav nav-pills">
                                <li className={this.state.activeTabInOut ? "active" : ""} onClick={() => this.onTabHandler("in")}>
                                    <a href="#1a" data-toggle="tab">Import products</a>
                                </li>
                            </ul>
                            <div className="dataexporttab-content tab-content clearfix">
                                <div className="tab-pane active" id="1a">

                                    <form>

                                        {/* <label className="select-field select-date-range" onClick={() => this.showmodalHandler("date")}> {this.state.labelDate} </label> */}



                                        {/* <label className="select-field select-supplier" onClick={() => this.showmodalHandler("sups")}> All suppliers </label> */}
                                        {/* <label className="select-field select-supplier" onClick={() => this.showmodalHandler("sups")}> {this.state.status}  </label> */}

                                        <div className="upload-btn-wrapper">
                                            {/* <input ref="file" type="file" name="file"
                                className="upload-file"
                                id="file"
                                onChange={(event) => this.handleImport(event)}
                                onClick={e => (e.target.value = null)}
                                encType="multipart/form-data"
                                required /> */}
                                            {/* <button  type="button" className="actionbutton primary">Import .xlsx file</button> */}

                                        </div>
                                        <input type="file" className="form-control" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="file"
                                            onChange={(event) => this.handleImport(event)}
                                            onClick={e => (e.target.value = null)}  style={{    marginRight: "10px"}}/>
                                        <button type="button" className="actionbutton primary" onClick={this.importData}>Import Data</button>
                                        <br/>
                                        <div className="form-element-group">
                                            <div className="buttonsection">

                                                {/* <button onClick={(event) => this.onDownload(event)}  type="button" className="actionbutton primary">Import .xlsx file</button> */}
                                            </div>
                                        </div>
                                    </form>
                                    <br></br>
                                    <div className="form-element-group">
                                    {orderSummary}
                                    <span class="errmsg" style={{ color : this.state.isError?"red":"green"}}>{this.state.error}</span>
                                    </div>

                                    {/* <ExportCSV  csvData={this.state.result} isValid={main  } fileName={this.state.fileName} /> */}

                                    {/* <br></br>
        <label>{noDataFoundText}</label> */}


                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>

        );
    }
}

export default DataExport;