import React, { Component } from 'react';
import ModalProfile from '../UI/Modal/ModalProfile';
import axios from '../../../axios-inventory';
import { authenticationService } from '../../../_services/authentication.service';
import { BehaviorSubject } from 'rxjs';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));


class Organisation extends Component {
  state = {
    items: Array.from({ length: 10 }),
    fetchedProduct: [],
    hasMore: true,
    show: false,
    showOrgEdit: false,
    offset: 0,
    limit: 10,
    searchText: '',
    storeId: authenticationService.currentUserValue.User["id"],
    storeData: []
  };


  getStoreDetails() {
    // storeId = this.state.storeid;

    axios.get(`/users/${this.state.storeId}`).then(res => {
      var fetchStoreDetails = res.data["Data"];
      let data = JSON.parse(localStorage.getItem('currentUser'));      
      data['User']['FirstName'] = fetchStoreDetails.firstName != undefined ? fetchStoreDetails.firstName : "";
      data['User']['LastName'] = fetchStoreDetails.lastName != undefined ?fetchStoreDetails.lastName : "";
      authenticationService.setUser(data);

      
      this.setState({ storeData: fetchStoreDetails });
    }).catch(err => {
      
    })

   
    // data = data.map((value) => {
    //     return value
    // })
  }


  

  showOrganisationEdit = (storeid) => {

    this.setState({ showOrgEdit: true });
    this.ModalReforg.onEditHandler(storeid);


  }

  hideOrganisationEdit = (userid, formData) => {
    this.setState({ showOrgEdit: false, });

    this.getStoreDetails();

    
  }

  

  componentDidMount() {
 
    this.getStoreDetails();
    
  }




  render() {
    return (
      <React.Fragment>
       

        <ModalProfile show={this.state.showOrgEdit}
          onHide={(userid, formData) => this.hideOrganisationEdit(userid, formData)}
          ref={ref => this.ModalReforg = ref} />

        <div className="slim-mainpanel">
          <div className="content-856">
            <div className="organisationsetting-top">
              <div className="left-sec">
                {/* <img src={this.state.storeData["profileImage"]} /> */}
                <div className="organisation-title">
                  <h2 className="page-title">{this.state.storeData["firstName"] + " " + this.state.storeData["lastName"]}</h2>
                  <span className="paragraph">{this.state.storeData["email"]}</span>
                </div>
              </div>
              <div onClick={() => this.showOrganisationEdit(this.state.storeId)} className="right-sec">
                <span className="editicon square" />
              </div>
              <img />
            </div>




          </div>
        </div>

      </React.Fragment>

    );
  }
}

export default Organisation;