import React, { Component } from 'react';
import { NavLink, Link, matchPath, withRouter } from 'react-router-dom';

class Sidebar extends Component {
    state = {}

    checkLink() {
        var abc = "sidemenu-icon"
        if (this.props.location.pathname == "/" || this.props.location.pathname == "/inventory") {
            abc = "sidemenu-iconactive";
        }
        return abc
    }

    render() {

        let subLink = this.checkLink();
        let mainLink = subLink == "sidemenu-iconactive" ? "active" : "";


        return (

            <div className="slim-sidebar">
                <ul className="nav nav-sidebar">

                    <li className="sidebar-nav-item">

                        <Link to="/inventory" className={`sidebar-nav-link ${mainLink}`}>

                            {/* <img className="sidemenu-icon" src= {matchPath(this.props.location.pathname, { path: "/" }) ? './img/ic_menu-inventory.svg' : './img/ic_menu-inventory.svg'} style={{fill:'green'}} /> */}


                            {/* <img className={matchPath(this.props.location.pathname, { path: "/inventory" }) ? 'sidemenu-iconactive' : matchPath(this.props.location.pathname, { path: "/" })? "sidemenu-iconactive" : 'sidemenu-icon'} src="./img/ic_menu-inventory.svg" /> Inventory </NaLink> */}
                            <img className={subLink} src="./img/ic_menu-inventory.svg" /> Inventory </Link>

                    </li>
                    <li className="sidebar-nav-item">
                        <NavLink to="/suppliers" className="sidebar-nav-link" activeClassName="sidebar-nav-link active">
                            <img className={matchPath(this.props.location.pathname, { path: "/suppliers" }) ? 'sidemenu-iconactive' : 'sidemenu-icon'} src="./img/ic_menu-suppliers.svg" />Suppliers</NavLink>

                    </li>
                    <li className="sidebar-nav-item">
                        <NavLink to="/customers" className="sidebar-nav-link" activeClassName="sidebar-nav-link active">
                            <img className={matchPath(this.props.location.pathname, { path: "/customers" }) ? 'sidemenu-iconactive' : 'sidemenu-icon'} src="./img/ic_menu-customers.svg" />Customers</NavLink>

                    </li>
                    <li className="sidebar-nav-item">
                        <NavLink to="/data" className="sidebar-nav-link" activeClassName="sidebar-nav-link active">
                            <img className={matchPath(this.props.location.pathname, { path: "/data" }) ? 'sidemenu-iconactive' : 'sidemenu-icon'} src="./img/ic_download.svg" />Data export</NavLink>

                    </li>
                    {/* <li className="sidebar-nav-item">
                        <NavLink to="/dataimport" className="sidebar-nav-link" activeClassName="sidebar-nav-link active">
                            <img className={matchPath(this.props.location.pathname, { path: "/dataimport" }) ? 'sidemenu-iconactive' : 'sidemenu-icon'} src="./img/ic_download.svg" />Templates Import</NavLink>

                    </li> */}
                </ul>
            </div>


        );
    }
}

export default withRouter(Sidebar);