import React, { Component, ReactDOM } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import axios from '../../../axios-inventory';
import ModalSuppliers from '../UI/Modal/ModalSuppliers';
import ReactTooltip from 'react-tooltip';
import { withAlert } from 'react-alert';


class Suppliers extends Component {
  state = {
    items: Array.from({ length: 10 }),
    fetchedProduct: [],
    isMoreData: true,
    offset: 0,
    limit: 10,
    hasMore: true,
    searchText: '',
    show: false,
    uid: "0"

  };

  fetchMoreData = () => {

    if (this.state.hasMore) {

      this.setState({
        offset: this.state.offset + this.state.limit
      }, () => {
        this.getSuppliers();

      });
    }
  };

  getSuppliers(searchText = '') {

    axios.get(`/suppliers`, {

      params: {
        searchText: searchText,
        offset: this.state.offset,
        limit: this.state.limit
      },


    }).then(res => {


      // for (let key in res.data["Data"]) {
      //   fetchOrders.push({ ...res.data["Data"][key], id: key });
      // }





      var fetchOrders = res.data["Data"];




      var fetchOrdersUpdated = this.state.offset != 0 ? [...this.state.fetchedProduct, ...fetchOrders] : fetchOrders





      this.setState({ fetchedProduct: fetchOrdersUpdated });


      if (res.data["Data"].length < this.state.limit) {
        this.setState({ hasMore: false });

      }

      


    }).catch(err => {
      
      this.setState({ hasMore: false });

    });
  }

  componentDidMount() {
    this.getSuppliers();
  }

  onsearch(text) {

    this.setState({
      fetchedProduct: [],
      offset: 0,
      hasMore: true,

    }, () => {
      this.getSuppliers(text);

    })
  }

  showmodalHandler = (id, text, status) => {

    if (this.tooltipRef != undefined) {
      this.tooltipRef.tooltipRef = null;
      ReactTooltip.hide();
    }
    this.setState({ show: true });

    if (text == "Add") {
      this.ModalRef.onModalOpenHandler();
    } else

      if (id != "0") {
        this.ModalRef.onEditHandler(id, text, status);
      }

  }
  onHideHandler = (userid, formData) => {

    this.setState({ show: false });

    if (formData == undefined) { return }
    var supIndex = this.state.fetchedProduct.findIndex(obj => obj.Id == userid);

    var sups = this.state.fetchedProduct;
    var s = sups[supIndex];


    if (Object.keys(formData).length == "1") {


      s.Status = formData["status"];

    } else {


      s.Name = formData["name"];
      s.ShortCode = formData["shortCode"];
      s.Featured = formData["isFeatured"];


    }
    sups.splice(supIndex, 1, s);


    this.setState({ fetchedProduct: sups })


  }


  render() {

    return (
      <React.Fragment>


        <ModalSuppliers show={this.state.show} onHide={(userid, formData) => this.onHideHandler(userid, formData)}
          id={this.state.uid}
          ref={ref => this.ModalRef = ref} />

        <div className="slim-mainpanel">
          <div className="table-top-section">
            <div className="left-sec">
              <h2 className="page-title" style={{ fontWeight: "bold" }}>Suppliers</h2>
            </div>



            <div className="right-sec">
              <form className="search-frm" onSubmit={e => { e.preventDefault() }}>
                <input type="text" name="search-product" onChange={(event) => { this.onsearch(event.target.value) }} placeholder="Search Suppliers...." />
              </form>
              {/* <a className="download-btn"><img src="./img/download-btn.svg" /></a> */}
              <button class="btn btn-success active btn-flex mg-b-10 mg-l-24 "
                onClick={() => this.showmodalHandler("0", "Add")}
                style={{
                  borderRadius: "5px", letterSpacing: "0.8px", backgroundColor: "#23A956", borderColor: "#23A956", textAlign: "center", alignItems: "center",
                  lineHeight: "17px", color: "white", marginLeft: "24px", width: "109px", height: "36px", fontSize: "12px"
                }}>
                <label style={{ marginLeft: "-4px", cursor:"pointer" }}> Add Supplier</label> </button>



            </div>

          </div>
          <div className="card card-table">
            <div className="tbl-header" style={{ background: "#F8F9FA" }}>
              <table className="table mg-b-0 tx-13 " style={{ width: "calc(100% - 1em)" }}>
                <thead>
                  <tr className="tx-10">
                    <th className="pd-y-5" style={{ width: "32.47%" }}>SUPPLIER NAME <img src="./img/Arrow.svg" /></th>
                    <th className="pd-y-5 tx-left" style={{ width: "5.81%" }}>SHORTCUT</th>
                    <th className="pd-y-5 tx-center" style={{ width: "10.23%" }}>TOP SUPPLIER</th>
                    <th className="pd-y-5 tx-left" style={{ width: "45.21%" }}>STATUS</th>
                    <th className="pd-y-5 tx-right" style={{ width: "6.28%" }}></th>
                  </tr>
                </thead>
              </table>
            </div>

            <div id="scrollableDiv" style={{ Width: '100%', overflow: 'auto', maxHeight: '600px' }}>
              <InfiniteScroll
                dataLength={this.state.fetchedProduct.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={<p style={{ textAlign: "center" }}>
                  <b>Loading....</b>
                </p>}
                height={400}
                endMessage={
                  <h2 style={{ textAlign: "center" }}>
                    <br /><br /><br />
                    {this.state.fetchedProduct.length > 0 ? "" : " No result found"}
                  </h2>}
                scrollableTarget="scrollableDiv"
              >
                <table className="table mg-b-0 tx-13 ">

                  <tbody>
                    {/* <a className="moreicon" data-for='soclose' data-tip={Supplier.Id}  data-event='click'>{Supplier.Id}...</a> */}

                    {/* <ReactTooltip className='customtooltip'  id='soclose'  clickable={true}
                        getContent={(dataTip) =>
                          <div className="tabletooltipinner">
                          <ul>
                            <li ><a>Edit Supplier info{dataTip}</a></li>
                            <li><a>Disable Supplier</a></li>
                          </ul>
                        </div> }
                        place="bottom" effect="float" type='light' offset={{ top: -60, left: -30 }}
 delayHide={200}
// delayShow={500}
//  delayUpdate={300}
/> */}
                    {this.state.fetchedProduct.map(Supplier => (

                      <tr className="valign-middle">
                        <td className="valign-middle" style={{ fontWeight: "bold", color: "black", width: "32.47%" }}>{Supplier.Name}</td>
                        <td className="valign-middle" style={{ width: "5.81%" }}>{Supplier.ShortCode} </td>
                        <td className="valign-middle tx-center" style={{ width: "10.23%" }}>{Supplier.Featured == "1" ? <img src="./img/Featured.png" /> : ""} </td>
                        <td className="valign-middle tx-left" style={{ width: "45.21%" }}>{Supplier.Status == "1" ? <img src="./img/button.png" /> : <img src="./img/Disabled.png" />}</td>
                        <td className="valign-middle tx-center" style={{ width: "6.8%" }}>

                          <a className="moreicon" data-tip={JSON.stringify(Supplier)} data-for="helloworld" data-event='click' ref={(c) => { this.ref = c; }}
                          // onMouseOver={() => { ReactTooltip.show(this.ref); }}

                          >...</a>
                          <ReactTooltip className='customtooltip' id="helloworld" clickable='true' ref={ref => this.tooltipRef = ref}
                            globalEventOff='click' place="left" effect="float" type='light'
                            getContent={datumAsText => {
                              if (datumAsText == null) {
                                return;
                              }

                              let d = JSON.parse(datumAsText);
                              return (
                                <div className="tabletooltipinner">
                                  <ul>
                                    <li ><a onClick={() => this.showmodalHandler(d.Id, "Edit", d.Status)} onMouseLeave={() => { ReactTooltip.hide(this.ref); }}>Edit Supplier info</a></li>
                                    <li><a onClick={() => this.showmodalHandler(d.Id, "Disable", d.Status)}
                                      onMouseLeave={() => { ReactTooltip.hide(this.ref); }}>{d.Status == "1" ? "Disable" : "Enable"} Supplier</a></li>
                                  </ul>
                                </div>

                              );
                            }}
                            offset={{ top: -60, left: -30 }}
                          >
                            {/* <div className="tabletooltipinner">
                              <ul>
                                <li ><a onClick={() => this.showmodalHandler(Supplier.Id)} >Edit Supplier info</a></li>
                                <li><a>Disable Supplier</a></li>
                              </ul>
                            </div> */}
                          </ReactTooltip>

                        </td>
                      </tr>
                    ))}


                  </tbody></table>

              </InfiniteScroll>
            </div>

          </div>
        </div>

      </React.Fragment>
    );
  }
}

export default withAlert()(Suppliers);
