import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import ModalStoreUser from '../UI/Modal/ModalStoreUser';
import ModalOrganisation from '../UI/Modal/ModalOrganisation';
import axios from '../../../axios-inventory';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { authenticationService } from '../../../_services/authentication.service';
import { withAlert } from 'react-alert';




class Organisation extends Component {
  state = {
    items: Array.from({ length: 10 }),
    fetchedProduct: [],
    hasMore: true,
    show: false,
    showOrgEdit: false,
    offset: 0,
    limit: 10,
    searchText: '',
    storeId: authenticationService.currentUserValue.User["StoreId"],
    storeData: []
  };


  getStoreDetails() {
    // storeId = this.state.storeid;
    
    axios.get(`/store/${this.state.storeId}`).then(res => {
      var fetchStoreDetails = res.data["Data"];

      this.setState({ storeData: fetchStoreDetails });
    }).catch(err => {

    })
  }

  getCustomer(searchText = '') {

    axios.get(`/users`, {

      params: {
        searchText: searchText,
        // category: '',
        offset: this.state.offset,
        limit: this.state.limit
      },


    }).then(res => {


      var fetchOrders = res.data["Data"];

      console.log(fetchOrders);

      var fetchOrdersUpdated = this.state.offset != 0 ? [...this.state.fetchedProduct, ...fetchOrders] : fetchOrders




      this.setState({ fetchedProduct: fetchOrdersUpdated });




      if (res.data["Data"].length < this.state.limit) {
        this.setState({ hasMore: false });

      }



    }).catch(err => {

      this.setState({ hasMore: false });

    });
  }


  fetchMoreData = () => {

    if (this.state.hasMore) {

      this.setState({
        offset: this.state.offset + this.state.limit
      }, () => {
        this.getCustomer();

      });
    }
  }


  showOrganisationEdit = (storeid) => {

    this.setState({ showOrgEdit: true });
    this.ModalReforg.onEditHandler(storeid);


  }

  hideOrganisationEdit = (userid, formData) => {
    this.setState({ showOrgEdit: false, });

    this.getStoreDetails();
  }

  showmodalHandler = (id, text, status) => {

    if (this.tooltipRef != undefined) {
      this.tooltipRef.tooltipRef = null;
      ReactTooltip.hide();
    }
    this.setState({ show: true });
    if (text == "Add") {

      this.ModalRef.onModalOpenHandler()
    } else

      if (id != "0") {
        this.ModalRef.onEditHandler(id, text, status);
      }
  }


  onHideHandler = (userid, formData) => {
    this.setState({ show: false });


    if (formData == undefined) {

      this.setState({
        fetchedProduct: [],
        offset: 0,
        hasMore: true,

      }, () => {
        this.getCustomer();

      })
      return

    }

    var supIndex = this.state.fetchedProduct.findIndex(obj => obj.Id == userid);

    var sups = this.state.fetchedProduct;
    let s = sups[supIndex];

    if (Object.keys(formData).length == "1") {

      s.status = formData["status"];

    }
    else {


      s.FirstName = formData["FirstName"];
      s.LastName = formData["LastName"];
      s.Email = formData["Email"];
      s.UserRole = formData["UserRole"];

    }


    sups.splice(supIndex, 1, s);


    this.setState({ fetchedProduct: sups })


  }

  componentDidMount() {
    // var abc = JSON.parse(localStorage.getItem('currentUser'))



    this.getStoreDetails();
    this.getCustomer();
  }


  onsearch(text) {

    this.setState({
      fetchedProduct: [],
      offset: 0,
      hasMore: true,

    }, () => {
      this.getCustomer(text);

    })
  }

  resendInvite(id){

    if (this.tooltipRef != undefined) {
      this.tooltipRef.tooltipRef = null;
      ReactTooltip.hide();
    }
    axios.put(`users/resendinvitation/${id}`,)
    .then(response => {
   
        if(response.data.Status != false){
          this.props.alert.success("Invitation sent successfully");
          }else{
              this.props.alert.error(response.data.Message);
          
          }
    })
    .catch(error => {
        console.log(error);
        this.props.alert.error(error.message);
        

    });


  }

  render() {
    return (
      <React.Fragment>
        <ModalStoreUser show={this.state.show}
          onHide={(userid, formData) => this.onHideHandler(userid, formData)}
          ref={ref => this.ModalRef = ref} />

        <ModalOrganisation show={this.state.showOrgEdit}
          onHide={(userid, formData) => this.hideOrganisationEdit(userid, formData)}
          ref={ref => this.ModalReforg = ref} />

        <div className="slim-mainpanel">
          <div className="content-856">
            <div className="organisationsetting-top">
              <div className="left-sec">
                <img src={this.state.storeData["profileImage"] == "" ? "./img/brandmark.svg" : this.state.storeData["profileImage"]} style={{Width:"90px" , height:"90px"}}/>
                <div className="organisation-title">
                  <h2 className="page-title">{this.state.storeData["name"]}</h2>
                  <span className="paragraph">{this.state.storeData["contactEmail"]}</span>
                </div>
              </div>
              <div onClick={() => this.showOrganisationEdit(this.state.storeId)} className="right-sec">
                <span className="editicon square" />
              </div>
              <img />
            </div>
            <div className="table-top-section">
              <div className="left-sec">
                <h2 className="page-title" style={{ fontWeight: "bold" }}>Users</h2>
              </div>
              <div className="right-sec">
                <form className="search-frm" onSubmit={e => { e.preventDefault() }}>
                  {/* onChange={(event) => { this.onsearch(event.target.value) }} */}
                  <input type="text" name="search-product" onChange={(event) => { this.onsearch(event.target.value) }} placeholder="Search Users...." />
                </form>
                <button class="btn btn-success active"
                  onClick={() => this.showmodalHandler("0", "Add")}
                  style={{
                    borderRadius: "5px", letterSpacing: "0.8px", textAlign: "center", backgroundColor: "#23A956", borderColor: "#23A956", alignItems: "center",
                    lineHeight: "17px", color: "white", marginLeft: "24px", width: "109px", height: "36px", fontSize: "12px"
                  }}> <label style={{ marginLeft: "-1px",cursor:"pointer" }}> Add User</label> </button>
              </div>
              <img />
            </div>


            <div className="card card-table">
              <div className="tbl-header" style={{ background: "#F8F9FA" }}>
                <table className="table mg-b-0 tx-13 " style={{ width: "calc(100% - 1em)" }}>
                  <thead>
                    <tr className="tx-10">
                      <th className="pd-y-5" style={{ width: "35.79%" }}>CUSTOMER NAME <img src="./img/Arrow.svg" /></th>
                      {/* <th className="pd-y-5" style={{ width: "9.54%" }}>SHORTCUT</th> */}
                      <th className="pd-y-5 " style={{ width: "26.40%" }}>E-MAIL FOR RECEIPTS</th>
                      <th className="pd-y-5 tx-left" style={{ width: "11.81%" }}>USER ROLE</th>
                      <th className="pd-y-5 " style={{ width: "20.82%" }}>STATUS</th>
                      {/* <th className="pd-y-5 tx-right" style={{ width: "9.43%" }}></th> */}
                      <th className="pd-y-5 tx-right" style={{ width: "175px" }}></th>


                    </tr>
                  </thead>
                </table>
              </div>
              <div id="scrollableDiv" style={{ Width: '100%', overflow: 'auto', maxHeight: '600px' }}>
                <table className="table mg-b-0 tx-13 ">
                  <InfiniteScroll
                    dataLength={this.state.fetchedProduct.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    loader={<p style={{ textAlign: "center" }}>
                      <b>Loading....</b>
                    </p>}
                    height={400}
                    endMessage={
                      <h2 style={{ textAlign: "center" }}>
                        <br /><br /><br />
                        {this.state.fetchedProduct.length > 0 ? "" : " No result found"}
                      </h2>}
                    scrollableTarget="scrollableDiv"
                  >
                    <tbody>
                      {this.state.fetchedProduct.map(storeUser => (
                        <tr className="valign-middle">
                          <td className="valign-middle" style={{ width: "35.79%" }}>{storeUser.FirstName + " "}{storeUser.LastName}</td>
                          {/* <td className="valign-middle" style={{ width: "9.54%" }}>{customer.shortcut}</td> */}
                          <td className="valign-middle tx-left" style={{ width: "26.40%" }}>{storeUser.Email}</td>
                          <td className="valign-middle tx-left" style={{ width: "11.81%" }} >{storeUser.UserRole}</td>
                          <td className="valign-middle tx-left" style={{ width: "20.82%" }}>{storeUser.status == "1" ? <img src="./img/button.png" /> : <img src="./img/Disabled.png" />} </td>
                          {/* <td className="valign-middle tx-left" style={{ width: "9.43%" }}><Link to="/customers/123" ><u style={{ color: "#168442" }}><b>See details</b></u></Link></td> */}
                          <td className="valign-middle tx-center" style={{ width: "175px" }}>

                            <a className="moreicon" data-tip={JSON.stringify(storeUser)}
                              data-for='customerTooltip' ref={(c) => { this.ref = c; }} data-event='click'>...</a>

                            <ReactTooltip className='customtooltip' id='customerTooltip' clickable='true'
                              globalEventOff='click' place="top" effect="float" type='light' ref={ref => this.tooltipRef = ref}
                              getContent={datumAsText => {
                                if (datumAsText == null) {
                                  return;
                                }

                                let d = JSON.parse(datumAsText);
                                return (
                                  <div className="tabletooltipinner" style={{ textAlign: "left" }}>
                                    <ul>
                                      <li ><a onClick={() => this.showmodalHandler(d.Id, "Edit", d.status)} onMouseLeave={() => { ReactTooltip.hide(this.ref); }}>Edit user info</a></li>
                                      <li ><a onClick={() => this.resendInvite(d.Id)} onMouseLeave={() => { ReactTooltip.hide(this.ref); }}>Resend invitation</a></li>
                                      <li><a onClick={() => this.showmodalHandler(d.Id, "Disable", d.status)}
                                        onMouseLeave={() => { ReactTooltip.hide(this.ref); }}>{d.status == "1" ? "Disable" : "Enable"} Customer App Access</a></li>
                                    </ul>
                                  </div>

                                );
                              }}
                              offset={{ left: 80 }}>

                            </ReactTooltip>
                          </td>

                        </tr>
                      ))}

                    </tbody>
                  </InfiniteScroll>
                </table>
              </div>{/* table-responsive */}
            </div>
          </div>
        </div>

      </React.Fragment>

    );
  }
}

export default withAlert()(Organisation);