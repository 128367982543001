import axios from "axios";
import { authenticationService } from "./_services/authentication.service";
import { urlImport } from './shared/basePath'




const key = authenticationService.currentUserValue != null ? authenticationService.currentUserValue.ApiKey : "";

const instance = axios.create({baseURL: urlImport});

instance.defaults.headers['ApiKey'] = key;



export default instance;