import React, { Component } from 'react';
import Modaldate from '../UI/Modal/Modaldate';
import ModaldateSup from '../UI/Modal/ModaldateSup';
import Modal from '../UI/Modal/Modaldate';
import axios from '../../../axios-inventory';
import ExportCSV from '../Inventory/ExportCSV/ExportCSV ';


class DataExport extends Component {
    state = {
        items: Array.from({ length: 10 }),
        hasMore: true,
        showSups: false,
        showDate: false,
        activeTabInOut: true,
        labelDate: " Select date range",
        suppliers: [],
        customers: [],
        fromDate: "",
        toDate: "",
        result: [],
        fileName: 'ExportSupData',
        status: "Select suppliers",
        isDateValid : false,
        isSupCustValid : false

    };

    getReport() {
        var url = this.state.activeTabInOut ? "in" : "out";

        
        
        
        // axios.get('/dataexport/in?fromdate=01June2019&todate=31August2019&suppliers=1').then(res => {
        //     console.log(res)
        //     this.setState({ result: res.data["Data"] })
        // }).catch(err => {
        //     console.log(err)
        // })
        
        axios.get(`/dataexport/${url}`, {

            params: {
                fromdate: this.state.fromDate,
                todate: this.state.toDate,
                suppliers: this.state.suppliers == "ALL" ?  this.state.suppliers : this.state.suppliers.join(","),
                customers: this.state.customers == "ALL" ?  this.state.customers : this.state.customers.join(",")
            },


          }).then(res => {
              console.log(res)
              this.setState({ result: res.data["Data"] });
          }).catch(err => {
              console.log(err)
          })
    }

    showmodalHandler = (name) => {

        if (name == "date")
            this.setState({ showDate: true });
        else if (name == "sups") {
            this.setState({ showSups: true });



        }

    }

    checkSupCusStatus(date) {


        if (date != "all") {
            const objsup = [];
            for (let i = 0; i < date.length; i++) {

                objsup[i] = date[i][`${this.state.activeTabInOut ? "Id" : "id"}`];

            }
            return objsup;

        } else {

            var objsup = "ALL"


            return objsup;

        }

    }

    getSupsCustsNames(date) {

        const objsup = [];
        for (let i = 0; i < date.length; i++) {

            objsup[i] = date[i][`${this.state.activeTabInOut ? "Name" : "name"}`];

        }

        return objsup;
    }


    onHideHandler = (name, date) => {

      console.log("GROM OHH");
        console.log(name);
        if (name == "sups") {

            var objsup = this.checkSupCusStatus(date);
            var checkTab = this.state.activeTabInOut?   "suppliers" : "customers"
            console.log("GROM SUPS");

            console.log(objsup);
            if (objsup == "ALL") {

                this.setState({status : "All " + checkTab, isSupCustValid: true}, ()=>{
                    this.checkValidSelection();
                })

             }
            else {

                if (objsup.length == 0) {

                     this.setState({status : "Select " + checkTab, isSupCustValid: false}, () => {
                        this.checkValidSelection();
                     })
                    }
                else {

                    this.setState({status : "Selected " + checkTab, isSupCustValid: true}, ()=>{
                        this.checkValidSelection();
                    })


                }
            }
            console.log("HELLO")
            console.log(objsup);
            if (this.state.activeTabInOut) {
                this.setState({ suppliers: objsup, customers: [] });

            } else {
                this.setState({ customers: objsup, suppliers: [] });

            }




            this.setState({ showSups: !this.state.showSups, labelDate: this.state.labelDate }, () => {
                console.log("sups :" + this.state.suppliers);
                console.log("custs :" + this.state.customers);
            });


        }
        if (name == "date") {

            var str1 = date.split(' ').join('');
            var abc = str1.split("-");
            if (date != " - ") {
                this.setState({ labelDate: date , isDateValid: false }, () => {
                    this.checkValidSelection();
                });
            }
            this.setState({ showDate: !this.state.showDate, fromDate: abc[0], toDate: abc[1] , isDateValid: true},()=>{
                this.checkValidSelection();
            });
        }

       

      
    }

    checkValidSelection(){

        if(this.state.isDateValid && this.state.isSupCustValid){
            console.log("Called Get Report")
            this.getReport();
        }else {
            this.setState({result:[]});
        }
    }

    onTabHandler(name) {

        this.ModalRef.ontabeChange();
        //this.ModalRef.getSuppliers();

        if (name == "in"){
            this.setState({ activeTabInOut: true, status: "Select suppliers",isSupCustValid: false , result: [] });
            this.ModalRef.getSuppliers("sups");


        }else if  (name == "out"){

            this.setState({ activeTabInOut: false, status:"Select customers" , isSupCustValid: false, result: []});
            this.ModalRef.getSuppliers("cust");
        }
    }

    onDownload= (event) =>{
        event.preventDefault();
        var sup = this.state.isSupCustValid ? "1" : "0";
        var date = this.state.isDateValid ? "1" : "0";
        console.log(sup+date);
        //   console.log(this.state.suppliers.join(","));
        //   this.getReport();
      }

    render() {
        var abc = this.state.isDateValid ? "1" : "0";
        var xyz = this.state.isSupCustValid ? "1" : "0";
        var main = abc+xyz;
        var noDataFoundText = this.state.result.length > 0 ? "Your Data is Ready to Downalod" :  main == "11" ? "No Data Avaialble" : "Please Select above fields" ;
        console.log("LEN of RESULT "+this.state.result.length );
        return (
            <React.Fragment>
                <Modaldate show={this.state.showDate} onHide={(mydate) => this.onHideHandler("date", mydate)} />
                <ModaldateSup show={this.state.showSups} ref={ref => this.ModalRef = ref} modalName={this.state.activeTabInOut} onHide={(formdata) => this.onHideHandler("sups", formdata)} />
                <div className="slim-mainpanel">
                    <div className="table-top-section">
                        <div className="left-sec">
                            <h2 className="page-title">Data export</h2>
                        </div>
                        <div className="right-sec">
                        </div>
                        <img />
                    </div>
                    <div className="customtabpanel">
                        <div id="exTab1" className>
                            <ul className="nav nav-pills">
                                <li className={this.state.activeTabInOut ? "active" : ""} onClick={() => this.onTabHandler("in")}>
                                    <a href="#1a" data-toggle="tab">Incoming products log</a>
                                </li>
                                <li className={this.state.activeTabInOut ? "" : "active"} onClick={() => this.onTabHandler("out")}>
                                    <a href="#1a" data-toggle="tab">Outgoing products log</a>
                                </li>
                            </ul>
                            <div className="dataexporttab-content tab-content clearfix">
                                <div className="tab-pane active" id="1a">
                                    <form>

                                        <label className="select-field select-date-range" onClick={() => this.showmodalHandler("date")}> {this.state.labelDate} </label>



                                        {/* <label className="select-field select-supplier" onClick={() => this.showmodalHandler("sups")}> All suppliers </label> */}
                                        <label className="select-field select-supplier" onClick={() => this.showmodalHandler("sups")}> {this.state.status}  </label>


                                        <div className="form-element-group">
                                            <div className="buttonsection">

                                                {/* <button onClick={(event) => this.onDownload(event)}  type="button" className="actionbutton primary">Download .csv file</button> */}
                                            </div>
                                        </div>
                                    </form>
                                    <ExportCSV  csvData={this.state.result} isValid={main  } fileName={this.state.fileName} /> <br></br>
        <label>{noDataFoundText}</label>
                                </div>
                                <div className="tab-pane" id="2a">
                                    <form>
                                        <div className="form-element-group">
                                            <select className="select-field select-date-range">
                                                <option value>Select date range</option>
                                            </select>
                                        </div>
                                        <div className="form-element-group">
                                            <select className="select-field select-supplier">
                                                <option value>All customers</option>
                                            </select>
                                        </div>
                                        <div className="form-element-group">
                                            <div className="buttonsection">
                                                <button type="submit" className="actionbutton primary">Download .csv file</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>

        );
    }
}

export default DataExport;