import React, { Component } from 'react';
import axios from '../../../../axios-inventory';


class ModaldateSup extends Component {

  suppliers = () => {
    let custs = []
    for (let i = 0; i <= 5; i++) {
      custs.push({
        name: `Adams Matkasse${i}`, value: `${i}`,
        toggle: false,

      });
    }
    return custs;
  }
  state = {
    fetcheditems: [],
    isSelectAll: false,
    hasMore: false,
    isSelected: false,

  }


  ontabeChange(){
    this.setState({ fetcheditems: [], isSelectAll: false, isSelected: false});
  }
  componentDidMount(){
    this.getSuppliers("sups");
  }

  getSuppliers(searchText = '') {


    var url = searchText == "sups" ? "suppliers" : "customer";

    axios.get(`/${url}`
    // {

    //   params: {
    //     searchText: searchText,
    //     offset: this.state.offset,
    //     limit: this.state.limit
    //   },}


    ).then(res => {

      console.log(res);
      
      if (searchText == "sups"){
      var fetchOrders = [...res.data["Data"].feature, ...res.data["Data"].nonFeature];
    }else{
      var fetchOrders = res.data["Data"];
    }


      for (let i = 0; i < fetchOrders.length; i++) {

        fetchOrders[i]["toggle"] = true;
      }


      

      // var fetchOrdersUpdated = [...this.state.fetcheditems, ...fetchOrders]





      this.setState({ fetcheditems: fetchOrders , isSelectAll : true , isSelected: true });


      if (res.data["Data"].length < this.state.limit) {
        this.setState({ hasMore: false });

      }





    }).catch(err => {
      console.log(err);
      this.setState({ hasMore: false });

    });
  }

  onCloseHandler = (fromdata = undefined) => {
    
    this.props.onHide(fromdata);
  }

  onSelectAllHandler(e) {
    e.preventDefault();



    this.setState({ isSelectAll: !this.state.isSelectAll });

    var sups = this.state.fetcheditems;
    sups.map((s) => {
      s.toggle = !this.state.isSelectAll;
    });
    this.setState({
      fetcheditems: sups
    }, () => {
      var abc = this.checkAllTrue();
      
      if (abc.length == 0) {
        this.setState({ isSelected: false, });
      }else{
        this.setState({ isSelected: true, });

      }
    });


  }

  checkAllTrue() {
    var abc = this.state.fetcheditems.filter(function (hero) {
      return hero.toggle == true;
    });

    return abc
  }

  onSelectSingleHandler = (index) => {


    var sups = this.state.fetcheditems;
    let s = sups[index];
    s.toggle = !s.toggle;
    sups.splice(index, 1, s);
    this.setState({
      suppliers: sups,
      isSelected: true,
    }, () => {
      var abc = this.checkAllTrue();
      if (abc.length == this.state.fetcheditems.length) {
        this.setState({ isSelectAll: true });
   
      } else if (abc.length == 0) {
        

        this.setState({ isSelectAll: false, isSelected: false, });

      }else if(abc.length > 0 && abc.length < this.state.fetcheditems.length ){
        this.setState({ isSelectAll: false, isSelected: true, });

      }
    });
  }




  onSaveHandler = () => {
    var abc = "all"
    if (!this.state.isSelectAll) {
      abc = this.state.fetcheditems.filter(function (hero) {
        return hero.toggle == true;
      });
    }

    this.onCloseHandler(abc);
  }
  render() {

    const showmodal = this.props.show;
    var classmy = "none";

    if (showmodal) {
      classmy = "flex";
    }


    return (
      <div id="mypopup" className="modalpopup selectboxpopup" style={{ display: classmy }}>
        {/* Modal content */}
        <div className="modal-popup-content">
          <div className="popup-header">
            <h2 className="popup-title"> Select {this.props.modalName ? "Suppliers" : "Customers"} </h2>
            <span className="popupclose" onClick={this.onSaveHandler}><img src="./img/closepopup-icon.svg" /></span>
          </div>
          <div className="popupbody">
            <div className="selctboxtopcontent">
              <h2 className="noofselection">{this.state.isSelected ? "" : "None selected"} </h2>
              <div className="selectoption">
                <a onClick={(e) => this.onSelectAllHandler(e)} className={this.state.isSelectAll ? "deactive" : ""} >Select all</a>
                <a onClick={(e) => this.onSelectAllHandler(e)} className={this.state.isSelectAll ? "" : "deactive"}  >Deselect all</a>
              </div>
            </div>
            <div id="scrollableDiv" style={{ Width: '100%', overflow: 'auto', maxHeight: '400px' }}>
              <table className="table mg-b-0 tx-13 ">
                <tbody>
                  {this.state.fetcheditems.map((supplier, index) => (
                    <tr className="valign-middle" key={index} onClick={() => this.onSelectSingleHandler(index)}>
                      <td className={supplier.toggle ? "valign-middle selected" : "valign-middle"}
                        style={{ width: '100%' }}>{this.props.modalName ? <div>{supplier.Name}</div> : <div>{supplier.name}</div>}</td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
            <div className="selctboxbottomcontent">
              <button onClick={this.onSaveHandler} type="submit" className="actionbutton primary">Done</button>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

export default ModaldateSup;