import React , { useState } from 'react';
import {Router, Route, Link  }from 'react-router-dom';

import { history } from '../src/_helpers/history';
import { authenticationService } from '../src/_services/authentication.service';
import { PrivateRoute } from '../src/_components/PrivateRoute';






import './App.css';
import Header from './components/Layout/Header/Header';
import Sidebar from './components/Layout/Sidebar/Sidebar';
import Mainpanel from './components/Layout/Mainpanel/Mainpanel';
import Inventory from './components/site/Inventory/Inventory';
import Suppliers from './components/site/Suppliers/Suppliers';
import Customers from './components/site/Customers/Customers';
import DataExport from './components/site/DataExport/DataExport';
import DataImport from './components/site/DataExport/DataImport';
import CustomerDetails from './components/site/Customers/CustomerDetails';
import Organisation from './components/site/Organisation/Organisation';
import Profile from './components/site/Organisation/Profile';
import Login from './LoginPage/Login';
import Reset from './LoginPage/Reset';





class App extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
        currentUser: null
    };
}

componentDidMount() {
    authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
}
  // var currentUser =  null;
  // authenticationService.currentUser.subscribe(x =>  currentUser =  x );
  render() {
    const { currentUser } = this.state;

  return (
    <div>
      <Router  history={history}>
      
   {/* <Header /> */}

   {currentUser && <Header />}



   <div className="slim-body" >
 
   {/* <PrivateRoute exact path="/" component={Sidebar} /> */}
   {currentUser && <Sidebar />}
   <PrivateRoute exact path="/" component={Inventory} />
   <PrivateRoute exact path="/inventory" component={Inventory} />
   <PrivateRoute exact path="/suppliers" component={Suppliers} />
   <PrivateRoute exact path="/customers" component={Customers} />
   <PrivateRoute exact path="/customers/:id" component={CustomerDetails} />
   <PrivateRoute exact path="/data" component={DataExport} />
   <PrivateRoute exact path="/dataimport" component={DataImport} />
   <PrivateRoute exact path="/orgs" component={Organisation} />
   <PrivateRoute exact path="/profile" component={Profile} />
   <Route path="/login" component={Login} />
   <Route path="/reset" component={Reset} />


    {/* <Sidebar></Sidebar> */}
    {/* <Route path="/" exact component={Inventory}/>
    <Route path="/inventory" exact component={Inventory}/>
    <Route path="/suppliers" exact component={Suppliers}/>
    <Route path="/customers" exact component={Customers}/>
    <Route path="/customers/:id" exact component={CustomerDetails}/>
    <Route path="/data" exact component={DataExport}/>
    <Route path="/orgs" exact component={Organisation}/> */}


        
    </div>
    </Router>
    </div>

    
  );
  }

}

export default App;
